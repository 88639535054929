import { ReactElement } from "react";
import { Link } from "@cruk/cruk-react-components";
import PhoneNumber from "./PhoneNumber";
import {
  isEmail,
  isUrl,
  isPhoneNumber,
  isTemplate,
  splitIntoFragments,
  stripTemplating,
} from "../helpers/functions/shared";
import Email from "./Email";

type CopyParagraphProps = {
  children: string;
};

/**
 * The current implementation expects the string with the following template:
 * 1. Plain string -> This will be rendered within the paragraph tag
 * 2. Templated string -> This will replace the templated string with a phone number or email.
 *
 * The template string syntax is as follows: "This is phone number [1234 5678] and this is an email [email]".
 * The function `splitIntoFragments` preserves the templating square brackets, so it still needs to be stripped creating a PhoneNumber or Email fragment.
 */
const IntroBannerCopyParagraph = ({
  children,
}: CopyParagraphProps): ReactElement => (
  <p>
    {splitIntoFragments(children)
      .map((fragment) => {
        if (isTemplate(fragment)) {
          if (isPhoneNumber(fragment)) {
            return <PhoneNumber>{stripTemplating(fragment)}</PhoneNumber>;
          }

          if (isEmail(fragment)) {
            return <Email>{stripTemplating(fragment)}</Email>;
          }

          if (isUrl(fragment)) {
            return (
              <Link href={stripTemplating(fragment)} target="_blank">
                {stripTemplating(fragment)}
              </Link>
            );
          }
        }

        return fragment;
      })
      .reduce((acc, fragment) => (
        <>
          {acc}
          {fragment}
        </>
      ))}
  </p>
);

export default IntroBannerCopyParagraph;
