import { nanoid } from "nanoid";
import { datadogRum } from "@datadog/browser-rum";
import {
  clearTransactionAttemptCache,
  clickableTelNum,
  isProductJourney,
  setTransactionAttemptSellerReference,
} from "../../helpers/functions/shared";
import { PAYMENT_API_GATEWAY_URL } from "../../helpers/functions/shared";

export type TokenizationData = {
  token: string;
  ipAddress: string;
};

export async function fetchTokenizationData(
  merchantAccountId: string,
  pushError: (
    errorCode: string,
    errorType: string,
    errorMessage: string
  ) => void
): Promise<TokenizationData | void> {
  try {
    const tokenizationResponse = await fetch(
      `${PAYMENT_API_GATEWAY_URL}tokenization`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          merchantAccountId: merchantAccountId,
        }),
      }
    );

    const { data: tokenizationData } = await tokenizationResponse.json();
    return {
      token: tokenizationData.createClientToken.clientToken,
      ipAddress: tokenizationData.ipAddress,
    };
  } catch (err: any) {
    if (err instanceof Error) {
      pushError("", "donation", err.message);
      console.error(err);
    }
  }
}

export const submitTransaction = async (
  nonce: string,
  donationAmount: string,
  merchantAccountId: string,
  metaData: any,
  reCaptchaId: string,
  getReCaptchaToken: any,
  failCallback: any,
  successCallback: any,
  formName: string
): Promise<void> => {
  const reCaptchaToken = await getReCaptchaToken();
  const correlationId = nanoid(10);
  let errorCode = "";

  if (isProductJourney(merchantAccountId)) {
    // TODO Check merchantID for donations; correlationID cache
    setTransactionAttemptSellerReference(metaData.sellerReference);
  } else {
    // transaction attempted for single donation
    sessionStorage.setItem(`transactionAttempted-${formName}`, "true");
  }

  fetch(`${PAYMENT_API_GATEWAY_URL}transaction`, {
    body: JSON.stringify({
      amount: donationAmount,
      correlationId: correlationId,
      merchantAccountId: merchantAccountId,
      metadata: metaData,
      transaction: nonce,
      recaptcha: {
        action: "pws_donation",
        token: reCaptchaToken,
        siteKey: reCaptchaId,
      },
    }),
    method: "POST",
  })
    .then((response) => {
      if (!response.ok) {
        errorCode = response.status.toString();
        return null;
      } else {
        return response.json();
      }
    })
    .then((data) => {
      clearTransactionAttemptCache();
      if (data) {
        if (data.error) {
          failCallback(data);
        } else {
          successCallback(data);
        }
      } else {
        const data = {
          error: {
            code: errorCode,
            message:
              "Sorry but we can't process your payment with this card. Please try again with another payment method or call us on 0300 123 1022 to make a payment.",
          },
        };
        failCallback(data);
      }
    })
    .catch((error) => {
      console.error(error);
    });
};

export const setErrorMessage = (
  message?: string,
  container?: HTMLDivElement
): void => {
  const errorContainer =
    container ||
    document.querySelector("[data-response-type='braintreeResponseOutput']");
  if (errorContainer) {
    if (message && message !== "") {
      errorContainer.innerHTML = `<p>${message.replace(
        "0300 123 1022",
        clickableTelNum("0300 123 1022")
      )}</p>`;
    } else {
      errorContainer.innerHTML = "";
    }
    if (errorContainer.offsetWidth > 0 && errorContainer.offsetHeight > 0)
      errorContainer.scrollIntoView();
  }
};

export const displayModal = (
  showModal: boolean,
  showSpinner: boolean
): void => {
  const loader = document.getElementById("loader");
  const spin = document.getElementById("spin");
  if (loader) loader.style.display = showModal ? "block" : "none";
  if (spin) spin.style.display = showSpinner ? "block" : "none";
  setErrorMessage();
};

export const validRequiredFormData = (isValid: boolean): void => {
  const errorMsg =
    "Please ensure all required form fields have been filled correctly.";
  if (!isValid) {
    setErrorMessage(errorMsg);
    datadogRum.addError("Form not filled", { errorType: "validation" });
  } else {
    setErrorMessage();
  }
};
