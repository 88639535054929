import React, { useContext, ReactElement, HTMLAttributes } from "react";
import styled, { css } from "styled-components";
import { ThemeType } from "@cruk/cruk-react-components";
import ThemeNameContext from "../../contexts/ThemeNameContext";
import { IntroBannerType } from "../../configs/single-donation";
import IntroBannerCopyParagraph from "../IntroBannerCopyParagraph";
import IntroHeader from "../IntroHeader";

type IntroDivProps = HTMLAttributes<HTMLDivElement> & {
  formTheme: string;
  theme?: ThemeType;
};

type IntroTextProps = HTMLAttributes<HTMLParagraphElement> & {
  formTheme: string;
  theme?: ThemeType;
};

const IntroBannerStyled = styled.div`
  ${({ theme, formTheme }: IntroDivProps) =>
    (formTheme === "SU2C" &&
      css`
        background-color: #ff8e00;
      `) ||
    css`
      background-color: ${theme.tokenColors.grey_200};
    `}
`;

const IntroBannerContainer = styled.div`
  max-width: ${({ theme }) => theme.utilities.contentMaxWidth};
  margin: 0 auto;
  padding: ${({ theme, formTheme }: IntroTextProps) =>
    formTheme === "SU2C"
      ? `${theme.spacing.xxs} ${theme.spacing.s} ${theme.spacing.xs}`
      : theme.spacing.s};

  p {
    text-align: ${({ formTheme }: IntroTextProps) =>
      formTheme === "SU2C" ? "left" : "center"};
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
    padding: ${({ theme, formTheme }: IntroTextProps) =>
      formTheme === "SU2C"
        ? `${theme.spacing.xs} ${theme.spacing.s} ${theme.spacing.l}`
        : theme.spacing.s};
  }
`;

const IntroText = styled.p`
  ${({ theme, formTheme }: IntroTextProps) =>
    (formTheme === "SU2C" &&
      css`
        display: table;
        color: ${theme.colors.textLight};
        text-transform: uppercase;
        font-family: ${theme.typography.fontFamilyHeadings};
        line-height: 1.2;
        margin-bottom: ${theme.spacing.xs};
        font-size: 1.2rem;

        @media (min-width: 405px) {
          font-size: 1.6rem;
        }

        @media (min-width: ${theme.breakpoint.tablet}) {
          font-size: 2.4rem;
        }

        @media (min-width: ${theme.breakpoint.desktop}) {
          font-size: 3.2rem;
        }
      `) ||
    css`
      color: ${theme.colors.textDark};
      font-family: ${theme.typography.fontFamilyHeadings};
      font-size: ${theme.fontSizes.xl};
      font-weight: normal;
      margin: 0 0 ${theme.spacing.xs};
      line-height: 1.5;
    `}

  span {
    ${({ theme, formTheme }: IntroTextProps) =>
      formTheme === "SU2C" &&
      css`
        display: table;
        background-color: ${theme.colors.tertiary};
        padding: 0 ${theme.spacing.xxs};

        @media (min-width: ${theme.breakpoint.tablet}) {
          &:first-child {
            margin-left: -${theme.spacing.xxs};
          }

          &:last-child {
            margin-left: ${theme.spacing.s};
          }
        }
      `}
  }
`;

const IntroBanner = ({
  header,
  copy,
  giftaid,
}: IntroBannerType): ReactElement => {
  const formTheme = useContext(ThemeNameContext);
  let defaultHeaderCopy;
  if (giftaid) {
    defaultHeaderCopy =
      formTheme === "SU2C"
        ? "Thank you for your donation\n to Stand Up To Cancer"
        : "Thank you for your donation to Cancer Research UK";
  } else {
    defaultHeaderCopy =
      formTheme === "SU2C"
        ? "You are doing\n something incredible."
        : "You are doing something amazing.";
  }
  const defaultBodyCopy =
    formTheme === "SU2C"
      ? "Your donation speeds up life-saving cancer research."
      : "Your donation will drive life-saving research.";

  return (
    <IntroBannerStyled formTheme={formTheme}>
      <IntroBannerContainer formTheme={formTheme}>
        <IntroText formTheme={formTheme}>
          <IntroHeader>{header || defaultHeaderCopy}</IntroHeader>
        </IntroText>
        {!giftaid ? (
          <IntroBannerCopyParagraph>
            {copy || defaultBodyCopy}
          </IntroBannerCopyParagraph>
        ) : (
          ""
        )}
      </IntroBannerContainer>
    </IntroBannerStyled>
  );
};

export default IntroBanner;
