import { ReactElement } from "react";

type IntroHeaderProps = {
  children: string;
};

const IntroHeader = ({ children }: IntroHeaderProps): ReactElement => (
  <>
    {children
      .split("\n")
      .filter((str) => str.trim() !== "")
      .map((fragment, i) => (
        <span key={i}>{fragment.trim()}</span>
      ))}
  </>
);

export default IntroHeader;
