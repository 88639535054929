import { createCipheriv } from "crypto";
import { NextRouter } from "next/router";
import { datadogRum } from "@datadog/browser-rum";
import {
  setErrorMessage,
  displayModal,
} from "../../payment-fields/hooks/braintreeClient";
// CONFIG
import { AdminType } from "../../configs/single-donation";
import { FormSchemaType } from "../../schema/single-donation";

export const getFailCallback = (
  formName: string,
  pushError: any,
  setSubmitting: any,
  setSubmitDisabled: any
): ((data: any) => void) => {
  const failCallback = (data: any) => {
    if (typeof window !== "undefined") {
      sessionStorage.removeItem(`transactionAttempted-${formName}`);
    }
    setSubmitting(false);
    setSubmitDisabled(false);
    displayModal(false, false);
    pushError(
      data.error.code ? data.error.code : "",
      "donation",
      data.error.message
    );
    console.error(data.error);
    setErrorMessage(data.error.message);
  };
  return failCallback;
};

export const getSuccessCallback = (
  formName: string,
  router: NextRouter
): ((data: any) => Promise<void>) => {
  const successCallback = async (data: any): Promise<void> => {
    datadogRum.setUserProperty("transactionId", data.id);
    if (typeof window !== "undefined") {
      sessionStorage.setItem(`transactionId-${formName}`, data.id);
      sessionStorage.setItem(`paymentMethod-${formName}`, data.paymentMethod);
      sessionStorage.removeItem(`transactionAttempted-${formName}`);
    }
    await router.push(`/${formName}/thanks`);
  };
  return successCallback;
};

export const getValidFormVersions = (formAdmin: AdminType): string[] => {
  // Check which versions of form there are (single/regular)
  const validFormVersions = [];
  if (
    formAdmin?.product?.single &&
    formAdmin?.source?.single &&
    formAdmin?.product?.collection &&
    formAdmin?.source?.collection &&
    formAdmin?.emailType?.single
  ) {
    validFormVersions.push("single");
  }
  if (
    formAdmin?.product?.regular &&
    formAdmin?.source?.regular &&
    formAdmin?.emailType?.regular
  ) {
    validFormVersions.push("regular");
  }
  return validFormVersions;
};

export const getDonationAmount = (donationData?: FormSchemaType): string =>
  donationData?.donationAmount.otherAmount
    ? Number(donationData.donationAmount.otherAmount).toFixed(2)
    : donationData?.donationAmount.amountRadioGroup
    ? Number(donationData.donationAmount.amountRadioGroup).toFixed(2)
    : "0.00";

export const getResearchAim = (
  researchArea: Record<string, unknown>
): string => {
  let outputString;
  switch (researchArea.value) {
    case "RES769":
      outputString = "in diagnosing cancer early";
      break;
    case "RES828":
      outputString = "in developing better treatments sooner";
      break;
    case "RES813":
      outputString = "in preventing cancer sooner";
      break;
    default:
      outputString = `to beat ${researchArea.label
        .toString()
        .toLowerCase()} sooner`;
      break;
  }

  return outputString;
};

export const encrypt = (key: string, message: string, id: string): string => {
  const iv = Buffer.from(id, "utf8").toString("hex").slice(0, 16);
  const cypher = createCipheriv("aes-256-cbc", key, iv);

  const encryptedMsg =
    cypher.update(message, "utf8", "hex") + cypher.final("hex");

  return encryptedMsg;
};
