import React, { ReactElement } from "react";
import styled from "styled-components";
import { Heading } from "@cruk/cruk-react-components";
import { CartDataType } from "../schema/payment-forms";
import { ThemeStyledProps } from "../helpers/types/shared";

type PaymentSummaryProps = {
  type?: string;
  frequency?: string;
  amount: string;
  cartData?: CartDataType;
};

const PaymentSummaryStyled = styled.div`
  margin-top: ${({ theme }) => theme.spacing.s};
  padding: ${({ theme }: ThemeStyledProps) =>
    `${theme.spacing.xs} ${theme.spacing.s} ${theme.spacing.s}`};
  background-color: ${({ theme }) => theme.tokenColors.grey_200};
`;

const PaymentSummaryContainer = styled.div`
  max-width: ${({ theme }) => theme.utilities.contentMaxWidth};
  margin: 0 auto;
  padding: 0;
`;

const PaymentSummaryContent = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
    margin: 0 auto;
    width: 70%;
  }
`;

const PaymentAmount = styled.p`
  display: flex;
  justify-content: space-between;
  margin: 0;
  display: block;

  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
    display: inline-block;
  }
`;

const CartDataList = styled.ul`
  list-style: none;
  padding: 0;
  margin-bottom: 0;

  li {
    display: flex;
    justify-content: space-between;
  }
`;

const PaymentSummary = ({
  type,
  frequency,
  amount,
  cartData,
}: PaymentSummaryProps): ReactElement => {
  let cartInfo;

  if (cartData) {
    const cartDataItems = cartData.items;
    const cartDataTotal = cartData.total;

    cartInfo = (
      <CartDataList>
        {cartDataItems
          ? cartDataItems.map((item, i) => (
              <li key={i}>
                <span>{item.label}</span>{" "}
                <span>
                  {item.label.toLowerCase().includes("discount") ? "-" : ""}
                  {item.value}
                </span>
              </li>
            ))
          : ""}
        {cartDataTotal ? (
          <li>
            <strong>{cartDataTotal.label}</strong>{" "}
            <strong>{cartDataTotal.value}</strong>
          </li>
        ) : (
          ""
        )}
      </CartDataList>
    );
  } else {
    cartInfo = (
      <PaymentAmount>
        {frequency || "Total"}: <span>£{amount}</span>
      </PaymentAmount>
    );
  }

  return (
    <PaymentSummaryStyled>
      <PaymentSummaryContainer>
        <PaymentSummaryContent>
          <Heading h2 marginBottom="xs">
            {type !== "payment" ? "Donation amount" : "Payment summary"}
          </Heading>
          {cartInfo}
        </PaymentSummaryContent>
      </PaymentSummaryContainer>
    </PaymentSummaryStyled>
  );
};

export default PaymentSummary;
