import { createContext, Dispatch, SetStateAction } from "react";

const initialValue: {
  transactionStatus: string | null;
  setTransactionStatus: Dispatch<SetStateAction<string>>;
} = {
  transactionStatus: null,
  setTransactionStatus() {},
};

const TransactionContext = createContext(initialValue);

export default TransactionContext;
