import { ReactElement } from "react";

type EmailProps = {
  mailto?: string;
  children: string;
};

const Email = ({ mailto, children }: EmailProps): ReactElement => (
  <a href={`mailto:${mailto || children}`}>{children}</a>
);

export default Email;
