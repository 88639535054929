// DEPENDENCIES
import React, {
  useContext,
  useEffect,
  useMemo,
  useState,
  ReactElement,
  PropsWithChildren,
  ReactNode,
} from "react";
import { datadogRum } from "@datadog/browser-rum";
import styled, { ThemeProvider } from "styled-components";
import { Header } from "@cruk/cruk-react-components";

// CONFIG
import {
  getFormAdmin,
  getFormBrand,
  getFormTheme,
  getFormThemeName,
  getFormComponents,
} from "../../configs/single-donation";

// CONTEXTS
import FormNameContext from "../../contexts/FormNameContext";
import TransactionContext from "../../contexts/TransactionContext";
import DonationContext from "../../contexts/DonationContext";
import ThemeNameContext from "../../contexts/ThemeNameContext";
import VersionsContext from "../../contexts/VersionsContext";
import { OptimizelyContextProvider } from "../../contexts/OptimizelyContext";

// STYLES
import GlobalStyle from "../../globalStyle";

// SCHEMA
import {
  FormSchemaType,
  initialFormValues,
} from "../../schema/single-donation";

// HELPERS
import {
  idleTimer,
  setCookieBanner,
  setCookieAccepted,
  setDatadog,
  formatTwoDecimals,
  setReCAPTCHAVisibility,
} from "../../helpers/functions/shared";
import {
  getValidFormVersions,
  getDonationAmount,
} from "../../helpers/functions/single-donation";
import { CheckoutProductsType } from "../../helpers/useDataLayer";

// COMPONENTS
import FormHead from "../FormHead";
import Footer from "../Footer";
import IntroBanner from "./IntroBanner";
import ProgressBar from "../ProgressBar";
import PaymentSummary from "../PaymentSummary";
import OptimizelySetup from "../OptimizelySetup";

const SiteContainer = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
`;

const DonationFormWrapper = ({
  children,
  page,
  paymentMethod,
  transactionId,
}: PropsWithChildren<{
  children?: ReactNode;
  page: string;
  paymentMethod?: string;
  transactionId?: string;
}>): ReactElement => {
  const formName = useContext(FormNameContext);
  const [transactionStatus, setTransactionStatus] = useState(null);
  const transactionStatusMemoized = useMemo(
    () => ({ transactionStatus, setTransactionStatus }),
    [transactionStatus]
  );
  const [formVersion, setFormVersion] = useState("");
  const formVersionMemoized = useMemo(
    () => ({ formVersion, setFormVersion }),
    [formVersion]
  );
  const donationDataFromStorage =
    typeof window !== "undefined"
      ? sessionStorage.getItem(`formData-${formName}`)
      : "";
  const [donationData, setDonationData] = useState<FormSchemaType>(
    donationDataFromStorage
      ? JSON.parse(donationDataFromStorage)
      : initialFormValues
  );
  const donationDataMemoized = useMemo(
    () => ({ donationData, setDonationData }),
    [donationData]
  );
  const [hasMounted, setHasMounted] = useState(false);
  const [scriptsLoaded, setScriptsLoaded] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const formAdmin = getFormAdmin(formName);
  const brand = getFormBrand(formName);
  const introBannerConfig = getFormComponents(formName).introBanner;
  const headerConfig = getFormComponents(formName).header;
  const progressStepsDetails = {
    routes: ["Your donation", "Details", "Payment", "Thanks"],
    steps: ["Donation", "Details", "Payment", "Thank you"],
  };
  const { steps, routes } = progressStepsDetails;
  const isErrorPage = ["error"].indexOf(page) > -1;
  const donationAmount = getDonationAmount(donationData);
  if (typeof window !== "undefined" && !donationDataFromStorage) {
    sessionStorage.setItem(
      `formData-${formName}`,
      JSON.stringify(initialFormValues)
    );
  }

  useEffect(() => {
    const validFormVersions = getValidFormVersions(formAdmin);
    const searchParams = new URLSearchParams(window.location.search);
    const formTypeQueryString =
      typeof window !== "undefined" ? searchParams.get("type") : "";
    const frequencyQueryString =
      typeof window !== "undefined" ? searchParams.get("frequency") : "";
    const storedFormVersion =
      typeof window !== "undefined"
        ? sessionStorage.getItem(`formVersion-${formName}`)
        : "";

    if (validFormVersions.length === 0 && typeof window !== "undefined") {
      // No valid source/product codes in config, redirect user
      datadogRum.addError("Form config error - no valid form versions");
      window.location.replace("http://www.cancerresearchuk.org");
    }

    if (storedFormVersion && !formTypeQueryString && !frequencyQueryString) {
      setFormVersion(storedFormVersion);
    } else {
      if (formTypeQueryString === "regular" || frequencyQueryString) {
        validFormVersions.includes("regular")
          ? setFormVersion("regular")
          : setFormVersion("single");
      } else {
        validFormVersions.includes("single")
          ? setFormVersion("single")
          : setFormVersion("regular");
      }
      sessionStorage.setItem(`formVersion-${formName}`, formVersion);
    }

    idleTimer(brand);
    setHasMounted(true);
  }, []);

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (sessionStorage.getItem(`formVersion-${formName}`) !== formVersion) {
        formVersion
          ? sessionStorage.setItem(`formVersion-${formName}`, formVersion)
          : sessionStorage.removeItem(`formVersion-${formName}`);
      }
    }
  }, [formVersion]);

  useEffect(() => {
    const progressIndex = routes.indexOf(page);
    const stepValue = progressIndex + 1;

    progressIndex > -1 && setCurrentStep(stepValue);
  }, [page, routes]);

  useEffect(() => {
    if (!scriptsLoaded && currentStep) {
      let checkoutDonationAmount;
      if (donationAmount && Number(donationAmount)) {
        checkoutDonationAmount = formatTwoDecimals(Number(donationAmount));
      } else {
        checkoutDonationAmount = 0;
      }
      const checkoutDonationAmountWithGA = donationData.giftAid
        ? formatTwoDecimals(
            Number((Math.round(Number(donationAmount) * 100) / 100) * 1.25)
          )
        : formatTwoDecimals(Number(donationAmount));
      const productSource =
        formVersion === "regular"
          ? formAdmin.source.regular
          : donationData.typeRadioGroup === "yes"
          ? formAdmin.source.single
          : formAdmin.source.collection;

      let dataLayerProducts = [] as CheckoutProductsType[];
      if (currentStep === 1) {
        dataLayerProducts = [
          {
            id: formVersion === "single" ? "Single Donation" : "donate_regular",
            name:
              formVersion === "single" ? "single donation" : "regular donation",
            category: "donation",
            brand: getFormBrand(formName),
            donation_event_code: formAdmin.formID,
          },
        ];
      } else {
        dataLayerProducts = [
          {
            id: formVersion === "single" ? "Single Donation" : "donate_regular",
            name:
              formVersion === "single" ? "single donation" : "regular donation",
            category: "donation",
            variant: formVersion === "regular" ? donationData.frequency : "",
            brand: getFormBrand(formName),
            price: Number(donationAmount) || 0,
            quantity: 1,
            donation_event_code: formAdmin.formID,
          },
        ];
      }

      setCookieAccepted(
        brand,
        page,
        checkoutDonationAmount,
        checkoutDonationAmountWithGA,
        currentStep,
        dataLayerProducts,
        transactionId,
        paymentMethod,
        productSource,
        donationData.restriction
      );

      const isSinglePaymentPage =
        typeof window !== "undefined" &&
        !window.location.href.includes("regular-payment");
      setReCAPTCHAVisibility(
        page === "Payment" && isSinglePaymentPage ? "visible" : "hidden"
      );
      setDatadog();
      setCookieBanner();
      setScriptsLoaded(true);
    }
    if (isErrorPage) {
      setCookieBanner();
      setCookieAccepted(brand, "404");
      setScriptsLoaded(true);
    }
  }, [currentStep]);

  if (!hasMounted) {
    return null;
  }

  return (
    <React.StrictMode>
      <VersionsContext.Provider value={formVersionMemoized}>
        <DonationContext.Provider value={donationDataMemoized}>
          <TransactionContext.Provider value={transactionStatusMemoized}>
            <OptimizelyContextProvider>
              <OptimizelySetup />
              <ThemeProvider theme={getFormTheme(formName)}>
                <ThemeNameContext.Provider value={getFormThemeName(formName)}>
                  <SiteContainer>
                    <GlobalStyle
                      theme={getFormTheme(formName)}
                      formTheme={getFormThemeName(formName)}
                    />
                    <FormHead
                      page={routes[currentStep - 1]}
                      title={
                        isErrorPage
                          ? "404 - Page not found | Cancer Research UK"
                          : getFormAdmin(formName).title
                      }
                      optimizelyId="2235241580"
                    />
                    <Header
                      logoAltText={headerConfig.logoAlt}
                      logoImageSrc={headerConfig.logoSrc}
                      logoLinkTitle={headerConfig.logoTitle}
                      logoLinkUrl={headerConfig.logoUrl}
                      siteSlogan={
                        headerConfig.siteSlogan ? headerConfig.siteSlogan : ""
                      }
                    />
                    <main id="main">
                      {/* TODO Remove this after Condensed Amount View Experiment */}
                      {formName === "support-us" ? (
                        <>
                          {!isErrorPage && (
                            <ProgressBar
                              steps={steps}
                              current={
                                currentStep === routes.length
                                  ? currentStep + 1
                                  : currentStep
                              }
                            />
                          )}
                          {currentStep === 3 && (
                            <PaymentSummary
                              type={
                                formVersion === "regular"
                                  ? "regular-donation"
                                  : "donation"
                              }
                              frequency={donationData.frequency}
                              amount={donationAmount}
                            />
                          )}
                        </>
                      ) : (
                        <>
                          {page !== "Thanks" && (
                            <IntroBanner
                              header={introBannerConfig?.header}
                              copy={introBannerConfig?.copy}
                            />
                          )}
                          {!isErrorPage && (
                            <ProgressBar
                              steps={steps}
                              current={
                                currentStep === routes.length
                                  ? currentStep + 1
                                  : currentStep
                              }
                            />
                          )}
                          {currentStep === 3 && (
                            <PaymentSummary
                              type={
                                formVersion === "regular"
                                  ? "regular-donation"
                                  : "donation"
                              }
                              frequency={donationData.frequency}
                              amount={donationAmount}
                            />
                          )}
                        </>
                      )}
                      {children}
                    </main>
                    <Footer formTheme={getFormThemeName(formName)} />
                  </SiteContainer>
                </ThemeNameContext.Provider>
              </ThemeProvider>
            </OptimizelyContextProvider>
          </TransactionContext.Provider>
        </DonationContext.Provider>
      </VersionsContext.Provider>
    </React.StrictMode>
  );
};

export default DonationFormWrapper;
